<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <el-form ref="form" :model="formData" label-width="80px">
      <el-form-item prop="userName" label="用户名" :rules="rules">
        <el-input
          size="small"
          v-model="formData.userName"
          placeholder="姓名"
        ></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="mobile" :rules="rules">
        <el-input
          size="small"
          v-model="formData.mobile"
          placeholder="手机号"
        ></el-input>
      </el-form-item>
      <el-form-item label="性别" prop="gender" :rules="rules">
        <el-select size="small" v-model="formData.gender" placeholder="请选择">
          <el-option
            v-for="item in genderList"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="roleId" label="角色" :rules="rules">
        <SelectRole style="width: 500px" v-model="formData.roleId"></SelectRole>
      </el-form-item>
      <el-form-item label="部门" prop="deptId" :rules="rules">
        <SelectDept style="width: 500px" v-model="formData.deptId"></SelectDept>
      </el-form-item>
      <el-form-item label="岗位" :rules="rules">
        <SelectJob style="width: 500px" v-model="formData.jobId"></SelectJob>
      </el-form-item>
      <el-form-item label=" ">
        <el-button size="small" type="" @click="cancel">取消</el-button>
        <el-button size="small" type="primary" @click="save">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import SelectRole from "@/views/components/select-role.vue";
import SelectDept from "@/views/components/select-dept.vue";
import SelectJob from "@/views/components/select-job.vue";
import { addAdmin, editAdmin, getAdminDetail, getRoleList } from "./api";
import { getDictEnumListAPI } from "@/enum/dict/index.js";
export default {
  name: "Detail",
  components: { SelectDept, SelectJob, SelectRole },
  data() {
    return {
      formData: {
        userName: "",
        mobile: "",
        roleId: null,
        gender: null,
        deptId: null,
        jobId: null,
      },
      rules: [{ required: true, message: "姓名不能为空", trigger: "blur" }],
      roleList: [],
      roleProps: {
        children: "children",
        label: "name",
        value: "id",
        emitPath: false,
        multiple: true,
      },
      genderList: [],
    };
  },

  mounted() {
    this.getRoleList();
    this.getDictEnumList();
    if (this.$route.params.id) this.getAdminDetail();
  },

  methods: {
    save() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          if (this.formData.id) {
            // 编辑
            await editAdmin(this.formData).then(() => {
              this.$message.success("编辑成功");
              this.$router.back();
            });
          } else {
            // 新增
            await addAdmin(this.formData).then(() => {
              this.$message.success("新增成功");
              this.$router.back();
            });
          }
        } else {
          return false;
        }
      });
    },
    async getAdminDetail() {
      this.formData = await getAdminDetail({ id: this.$route.params.id });
      this.formData.gender = this.formData.gender.toString();
    },
    cancel() {
      this.$router.back(-1);
    },
    async getRoleList() {
      const res = await getRoleList({ pageSize: 50, page: 1 });
      this.roleList = res.list || [];
    },
    async getDictEnumList() {
      this.genderList = await getDictEnumListAPI("user:gender");
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .el-form /deep/ {
    .el-form-item {
      .el-form-item__content {
        width: 500px;
        .el-cascader,
        .el-select {
          width: 100%;
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.el-form /deep/ {
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
}
</style>
